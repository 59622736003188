<template>
  <div>

    <edit-project
        v-model="showProjectEdit"
        :opts="projectOpts"
        @onChange="onProjectEditChanged"

    ></edit-project>


    <v-dialog v-model="dialogDelete" max-width="530px">
      <v-card>
        <v-card-title class="text-h6">
          ¿Estás seguro de que quieres eliminar el proyecto?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">{{
            $t("cancel")
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>Vision Proyectos Global</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="primary" dark class="mb-2" @click="onItemEdit()">
              Nuevo Proyecto
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              ref="tableRef"
              id="projects_table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="projects"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
              :scroll-width="1500"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totalprojects"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import moment from "moment";
import { logInfo, logError } from "@/utils";
import download from "js-file-download";
import { mapGetters } from "vuex";
import _ from "lodash";
import editProject from "../common/editProject";
import { TEMPLATE_TYPES } from "../common/common";


export default {
  name: "GlobalProjects",
  components: {
    editProject
  },
  data: function() {
    return {

      TEMPLATE_TYPES,

      filterable_col_list: [
        "name",
        "brandId",
        "responsibleId",
        "status",
        "startDate",
        "endDate",
        "tasksOnTime",
        "tasksDelayed",
        "highPriorityTasksDelayed"
      ],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterCriteria: {
        status: {
          open: "open"
        },
        templateType: {},
        name: {},
        brandId: {},
        responsibleId: {},
        startDate: {},
        endDate: {},
        tasksOnTime: {},
        tasksDelayed: {},
        highPriorityTasksDelayed: {}
      },
      showEmpty: false,

      projectOpts: {},
      showProjectEdit: false,

      totalprojects: 0,
      projects: [],

      /********************* Dialog *********************/


      deleteItemId: -1,
      dialogDelete: false,
    };
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  computed: {
    ...mapGetters("auth", ["user"]),

    columns() {
      return [
        {
          key: "name",
          title: "Proyecto",
          field: "name",
          align: "left",
          fixed: "left",
          width: 130,
          sortBy: "",
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["name"]}
                    apiUrl={`projects/getfilterlist`}
                    columnName={`name`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["name"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "name")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "templateType",
          title: "Plantilla",
          field: "templateType",
          align: "left",
          width: 120,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{this.getTemplateText(row.templateType)}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                  <div class="custom-filter">
                    <v-checkbox
                        v-model={this.filterCriteria["templateType"]["PROJECT_START"]}
                        label="Inicio Proyecto"
                        value="PROJECT_START"
                    ></v-checkbox>
                    <v-checkbox
                        v-model={this.filterCriteria["templateType"]["GPVINCREASE"]}
                        label="Aumento GPV's"
                        value="GPVINCREASE"
                    ></v-checkbox>
                    <v-checkbox
                        v-model={this.filterCriteria["templateType"]["NEWCHANNEL"]}
                        label="Nuevo Canal"
                        value="NEWCHANNEL"
                    ></v-checkbox>
                    <v-checkbox
                        v-model={this.filterCriteria["templateType"]["OTHERS"]}
                        label="Otros"
                        value="OTHERS"
                    ></v-checkbox>
                    <div class="custom-filter-operation">
                      <v-btn
                          color="blue darken-1"
                          text
                          on-click={() => this.searchCancel(closeFn, "templateType")}
                      >
                        {this.$t("cancel")}
                      </v-btn>
                      <v-btn
                          color="blue darken-1"
                          text
                          on-click={() => this.searchConfirm(closeFn)}
                      >
                        {this.$t("Search")}
                      </v-btn>
                    </div>
                  </div>
              );
            }
          }
        },
        {
          key: "responsibleId",
          title: "Responsable",
          field: "responsibleId",
          align: "left",
          fixed: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <span>{row.user && this.formatName(row.user)}</span>
            );
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["responsibleId"]}
                    apiUrl={`projects/getfilterlist`}
                    columnName={`responsibleId`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["responsibleId"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "responsibleId")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "brandId",
          title: "Marca",
          field: "brandId",
          align: "left",
          width: 180,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.brand?.name}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["brandId"]}
                    apiUrl={`projects/getfilterlist`}
                    columnName={`brandId`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["brandId"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "brandId")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "status",
          title: "Estado",
          field: "status",
          align: "left",
          width: 120,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.status === "OPEN" ? "ABIERTO" : "CERCA"}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["open"]}
                    label="ABIERTO"
                    value="open"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["close"]}
                    label="CERCA"
                    value="close"
                  ></v-checkbox>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "status")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "startDate",
          title: "Fecha Inicio",
          field: "startDate",
          align: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.startDate}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["startDate"]["from"]}
                    value={this.filterCriteria["startDate"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["startDate"]["to"]}
                    value={this.filterCriteria["startDate"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "startDate")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "endDate",
          title: "Fecha Final",
          field: "endDate",
          align: "left",
          width: 150,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.endDate}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["endDate"]["from"]}
                    value={this.filterCriteria["endDate"]["from"]}
                    placeholder="From - "
                  />
                  <br />
                  <input
                    type="date"
                    class="filter_date_picker"
                    v-model={this.filterCriteria["endDate"]["to"]}
                    value={this.filterCriteria["endDate"]["to"]}
                    placeholder="To - "
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "endDate")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "tasksCompleted",
          title: "Tareas completadas",
          field: "tasksCompleted",
          align: "left",
          width: 200,
          sortBy: "",
        },
        {
          key: "tasksPending",
          title: "Tareas pendientes",
          field: "tasksPending",
          align: "left",
          width: 200,
          sortBy: "",

        },
        {
          key: "commercialTasksNextMonth",
          title: "Tareas Comerciales Pendientes prox. mes",
          field: "commercialTasksNextMonth",
          align: "left",
          width: 200,
          sortBy: "",
        },
        {
          key: "tasksDelayed",
          title: "Tareas con Retraso",
          field: "tasksDelayed",
          align: "left",
          width: 200,
          sortBy: "",
        },
        {
          key: "highPriorityTasksDelayed",
          title: "Tareas Prio Alta con Retraso",
          field: "highPriorityTasksDelayed",
          align: "left",
          width: 200,
          sortBy: "",
        },
        {
          key: "docFile",
          title: "Adjuntos",
          field: "docFile",
          align: "center",
          width: 100,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                {row.static && (
                  <v-btn
                    color="primary"
                    dark
                    fab
                    on-click={() => this.downloadPDF(row.static)}
                  >
                    <b-icon icon="download" aria-hidden="true"></b-icon>
                  </v-btn>
                )}
              </div>
            );
          }
        },
        {
          key: "actions",
          title: "Editar",
          field: "actions",
          align: "center",
          fixed: "right",
          width: 120,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="info"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  medium
                  color="error"
                  class="mr-2"
                  on-click={() => this.onItemRemove(row.id)}
                >
                  mdi-delete
                </v-icon>
              </div>
            );
          }
        },
        {
          key: "Ir",
          title: "Ir",
          field: "Ir",
          align: "center",
          fixed: "right",
          width: 100,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-btn
                  color="blue darken-1"
                  text
                  on-click={() => this.onItemGo(row)}
                >
                  <b-icon icon="box-arrow-up-right" aria-hidden="true"></b-icon>
                </v-btn>
              </div>
            );
          }
        }
      ];
    }
  },
  methods: {
    async onItemGo(item) {
      this.$router.push({
        name: "individual_project",
        params: {
          id: item.id
        }
      });
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#projects_table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },

    close() {
      this.loadingInstance.close();
    },



    onItemEdit(opts) {
      this.projectOpts = opts || {}
      this.showProjectEdit = true
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },

    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },

    getTemplateText(value) {
      const found = this.TEMPLATE_TYPES.find(el=>el.value === value)
      return found ? found.text : 'Otros'
    },


    async onProjectEditChanged() {
      await this.getDataFromApi();
    },




    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deleteItemId = -1;
      });
    },

    onItemRemove(item_id) {
      this.deleteItemId = item_id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      if (this.deleteItemId > 0) {
        this.closeDelete();
        ApiService.delete(`projects/${this.deleteItemId}`).then(() => {
          logInfo("Proyectos eliminada");
          this.getDataFromApi();
        });
      }
    },

    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      let today = moment(new Date())
        .local()
        .format("YYYY-MM-DD");
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      query_params.push("today=" + today);

      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      let filter_columns = document.querySelectorAll(
        "#projects_table .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },

    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "projects";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const resp = await ApiService.get(fetch_url);
      this.projects = resp.data;
      this.totalprojects = resp.total;

      if (this.projects.length > 0) {
        this.showEmpty = false;
      } else {
        this.showEmpty = true;
      }
      this.close();
    },

    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },

    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },

    formatName(item) {
      return  [item.name, item.surname].filter(el=>!!el).join(' ')
    },

    init() {
      this.getDataFromApi();
    }
  },
  async mounted() {
    this.init();
  }
};
</script>

<style></style>
